import styled from 'styled-components';

import { Container, Button } from '../index';
import { IGalleryPrevNextButton } from './Gallery.i';


export const GallerySection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const GalleryContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  min-height: ${(props)=> props.minHeight || '500px'};

  ${(props) => props.theme.phone} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${(props) => props.theme.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const GalleryFigure = styled.figure`
  img {
    max-width: 100%;
  }
`;

export const GalleryPrevNext = styled(Button)<IGalleryPrevNextButton>`
  font-size: 2.5rem;
  padding-top: 20px;
  height: 100px;
  color: ${(props) => props.theme.black[0]};
  align-self: center;
  transition: color 0.25s, visibility 0s;

  ${(props) => props.align && `
    position: absolute;
    ${props.align}: -15px;
    z-index: 99999999;
  `}
  
  ${(props) => props.theme.phone} {
    padding-top: 20px;
    color: ${(props) => props.align ? props.theme.black[0] : props.theme.black[50]};
  }

  &[aria-hidden=true] {
    visibility: hidden;
  }

  &:hover, &:focus, &:active {
    color: ${(props) => props.theme.black[0]};
    border: none;
    outline: none;
  }

  &:before {
    display: none;
  }
`;

export const GalleryButton = styled(Button)`
`;

export const GalleryModalContainer = styled.div`
  display: flex;
  justify-content: center;
`;
