import React, { useContext, useState } from 'react';
import { Offcanvas } from 'src/components/Header/Offcanvas/Offcanvas';
import { MainMenu } from 'src/components/Header/Main/Main';
import { AccountMenu } from 'src/components/Header/Account/Account';
import {
  Navbar,
  Logo,
  AccountButtons,
  Login,
  Placeholder,
  Toggle,
  HeaderContentWrapper,
} from 'src/components/Header/Header.style';
import { LoginContext } from 'src/lib/LoginContext';
import { ScrollContext } from 'src/lib/ScrollContext';

import { ManagedFlexModal, OutlineButton } from '@riac/design-system';
import { TextButton } from '@riac/design-system';
import { SkipLink } from 'src/components/SkipLink/SkipLink';
import RIACLogo from 'images/riac-logo.png';
import { SocketContext } from 'src/lib/SocketContext';
import { FaExclamationTriangle } from 'react-icons/fa';

export const Header = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const { isLoggedIn } = useContext(LoginContext);
  const { headerRef } = useContext(ScrollContext);
  const { showIcon, showModal, setShowModal } = useContext(SocketContext);

  return (
    <div>
      <Navbar ref={headerRef}>
        <SkipLink/>

        <HeaderContentWrapper>
          <Logo to="/">
            <img src={RIACLogo} alt="Rock Island Auction Company"/>
          </Logo>
        </HeaderContentWrapper>

        {showIcon
          && <div className={'bg-red-800 p-2 rounded-md'}>
            <div className={'text-white'} onClick={() => setShowModal(true)}>
              <FaExclamationTriangle/>
            </div>
          </div>
        }
        <ManagedFlexModal
          title={'Disconnected'}
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          isDismissable
          showCloseButton
        >
          <div className={'py-5 text-2xl bg-red-500 text-white text-center mt-8'}>
            Unable to connect to live update server, attempting reconnection. If this persists, please try refreshing
            your browser.<br />
            <span className={'text-sm'}>
              Disconnected state indicated by <FaExclamationTriangle /> at top of page.  If you cannot see this icon,
              you are connected.
            </span>
          </div>
        </ManagedFlexModal>
        <MainMenu/>

        <HeaderContentWrapper>
          {isLoggedIn
            ? <AccountMenu showOn="desktop"/>
            : (
              <AccountButtons>
                <TextButton to="/create-account">
                  Create Account
                </TextButton>
                <Login>
                  <OutlineButton to="/login">
                    Login
                  </OutlineButton>
                </Login>
              </AccountButtons>
            )
          }
        </HeaderContentWrapper>

        <Toggle active={showOffcanvas} onPress={() => setShowOffcanvas(!showOffcanvas)} aria-label="Main Menu">
          <span/><span/><span/>
        </Toggle>
        <Offcanvas show={showOffcanvas} toggle={() => setShowOffcanvas(!showOffcanvas)}/>
      </Navbar>

      <Placeholder/>
      {isLoggedIn
        ? <AccountMenu showOn="mobile"/>
        : <div/>
      }
      <a id="maincontent"/>
    </div>
  );
};
