/*
  Most string fields are strict string fields, _text_ is a catch all for all
  text with tokenizing and analyzing and all that. headline and description are also parsed.

  id is a string because that's how solr does ids. The id also has no relation to the internal ID.

  hammerPrice and priceRealized are null on items that haven't sold yet.
  priceRealized is the actual amount payed less tax and shipping

  /backend/api/controllers/catalog/landingPage.js contains the backend code
  that passes in the auction to the landing page queries.

  The default query passes q.alt=*.*, this lets you ignore the default string
  query behaviour and treat it like a regular document query

  https://lucene.apache.org/solr/guide/7_5/the-standard-query-parser.html
  This documents most of the query options.
  There's also dismax/extended dismax parsers and
  https://lucene.apache.org/solr/guide/7_5/function-queries.html
  function queries can be useful.
*/

const allData = [{
  slug: 'antique-guns',
  title: 'Antique Guns For Sale',
  description: 'By definition, antique guns are those made in or before 1898, '
    + 'or replicas thereof, that use a primitive ignition system, or '
    + 'ammunition that is no longer commercially available.',
  queries: (info) => [{
    title: 'Top Antique Guns of All Time',
    query: [
      'bf=priceRealized',
      'defType=edismax',
      'fq=!hlo:O',
      'fq=amcode:*A*',
      'rows=3',
    ],
  }, ...info.auctions.map((auction) => ({
    title: `Antiques from Auction ${auction.AuctionID}`,
    links: [{
      label: 'View all Antique Guns',
      to: `/catalog/${auction.AuctionID}/antique-firearms`,
    }],
    query: [
      'bf=sqrt(sum(lowEst,highEst))',
      'defType=edismax',
      'fq=!hlo:O',
      'fq=amcode:*A*',
      'rows=6',
      `fq=auctionId:${auction.AuctionID}`,
    ],
  }))],
}, {
  slug: 'used',
  title: 'Why Purchase our Used Guns for Sale?',
  description: 'Used guns are a specialty at Rock Island Auction. This category includes '
    + 'everything from work horse firearms to antique collectable guns. Across this spectrum '
    + 'we find the best value in used guns and bring them to you with five auctions per year. '
    + 'Start exploring our options today!',
  queries: (info) => [{
    title: 'Shop our Used Guns for Sale',
    query: [
      'defType=edismax',
      'bf=itemViews',
      'fq=!hlo:O',
      'fq=amcode:*A*',
      'fq=rareItem:false',
      'fq=lowEst:[500 TO 3000]',
      'fq=lotItemCount:1',
      `fq=auctionId:(${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')})`,
      'rows=6',
    ],
  }],
}, {
  slug: 'pistols',
  title: 'Looking for Pistols for Sale?',
  description: 'Rock Island Auction’s selection of old pistols for sale are available to '
    + 'any level of enthusiast from beginner to investors and collectors. Click now to '
    + 'browse our auctions and create an account today! ',
  queries: (info) => [{
    title: 'Looking for Pistols for Sale?',
    query: [
      'defType=edismax',
      'bf=itemViews',
      'fq=!hlo:O',
      'fq=!hlo:L',
      'fq=amcode:*A*',
      'fq=rareItem:false',
      'fq=lowEst:[500 TO 5000]',
      'fq=lotItemCount:1',
      `fq=auctionId:(${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')})`,
      'rows=6',
    ],
  }],
}, {
  slug: 'gun-prices',
  title: 'About our Gun Prices',
  description: 'Wondering what your used gun is worth? With our firearm expertise and customer '
    + 'service we strive to bring you a memorable experience when shopping for the best gun prices. '
    + 'Learn more about the processes of buying and selling used guns.',
  queries: () => [{
    title: 'Explore Gun Prices for All Budgets',
    query: [],
  }],
}, {
  slug: 'dueling-pistols',
  title: 'Dueling Pistols for Sale',
  description: 'Are you looking for a collectible set of dueling pistols? Visit Rock Island Auction today to see '
  + 'the enormous variety of our dueling pistol auctions. Browse completed listings to plan for your next investment. '
  + 'Create an account today & get ready for our next auction!',
  queries: () => [{
    title: 'Dueling Pistols for Sale',
    query: [
      'defType=edismax',
      'fq=dueling',
      'fq=!hlo:O',
      'fq=!hlo:L',
      'sort=auctionId desc',
      'rows=3',
    ],
  }],
}, {
  slug: 'derringer',
  title: 'Learn more about Derringer Pistols for Sale',
  description: 'Are you looking for a collectible derringer pistol for sale? Visit Rock Island Auction today to '
  + 'browse our extensive catalog of derringer pistol auctions. Browse completed listings to plan for your '
  + 'next investment, create an account today & get ready for our next auction!',
  queries: (info) => [{
    title: 'Derringers For Sale',
    query: [
      'defType=edismax',
      'fq=deringer',
      'fq=!hlo:O',
      'fq=!hlo:L',
      `fq=auctionId:(${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')})`,
      'rows=3',
    ],
  }],
}, {
  slug: 'flintlock',
  title: 'Flintlocks for Sale',
  description: 'Rock Island Auction Company helps firearm enthusiasts find the exact antique guns they are looking '
    + 'for. Especially when it comes to flintlock firearms.',
  queries: (info) => [{
    title: 'Flintlocks For Sale',
    query: [
      'defType=edismax',
      'fq=flintlock',
      'fq=!hlo:O',
      `fq=auctionId:(${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')})`,
      'rows=3',
    ],
  }],
}, {
  slug: 'winchester/antique',
  title: 'Winchester Antique Firearms',
  description: 'Shop our selection of high-quality and rare Winchester antiques for sale. Rock Island Auction '
    + 'Company offers an outstanding variety of Winchesters, from the quintessential lever actions, to Winchester\'s '
    + 'sporting models. Browse our catalog online today or visit an upcoming gun auction.',
  queries: (info) => {
    if(info.auctions.find((auction) => auction.AuctionTypeID !== 3)) {
      return [{
        title: 'Winchester Antique Firearms',
        query: [
          'defType=edismax',
          'fq=winchester',
          'fq=!hlo:O',
          'fq=amcode:*A*',
          `fq=auctionId:(${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')})`,
          'fq=manufacturerName:*Winchester*',
          'rows=3',
        ],
      }];
    } else {
      return [{
        title: 'Top Winchester Antique Firearms',
        query: [
          'defType=edismax',
          'fq=winchester',
          'fq=!hlo:O',
          'fq=amcode:*A*',
          'fq=priceRealized:[0.01 TO *]',
          'fq=manufacturerName:*Winchester*',
          'sort=priceRealized desc',
          'rows=3',
        ],
      }];
    }
  },
}, {
  slug: 'garand',
  title: 'M1 Garand for Sale',
  description: 'Are you looking for an M1 Garand for sale? Visit Rock Island Auction Company today to browse our '
    + 'extensive catalog of M1 Garand Auctions. Browse completed listings to plan for your next investment, create '
    + 'an account today and get ready for our next Auction!',
  queries: (info) => [{
    title: 'Shop RIAC',
    query: [
      'defType=edismax',
      'fq=headline:garand',
      'fq=!hlo:H',
      'fq=!hlo:O',
      `fq=auctionId:((${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')}) OR
                    (${(info.auctions.map((auction) => (auction.AuctionID - 1))).join(' OR ')}) OR
                    (${(info.auctions.map((auction) => (auction.AuctionID - 2))).join(' OR ')}))`,
      'sort=auctionId desc',
      'rows=3',
    ],
  }],
}, {
  slug: 'revolvers/antique',
  title: 'Antique Revolvers for Sale',
  description: 'Are you in the market for an antique revolver? Rock Island Auction has an impressive '
    + 'variety of antique revolvers for all levels of gun collectors and enthusiasts. Click now to browse '
    + 'past and future auction listings or create an account today! ',
  queries: (info) => [{
    title: 'Shop our Antique Revolvers for Sale',
    query: [
      'defType=edismax',
      'fq=revolver',
      'fq=!hlo:L',
      'fq=!hlo:O',
      `fq=auctionId:(${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')})`,
      'rows=3',
    ],
  }],
}, {
  slug: 'flintlock/hunting',
  title: 'Flintlock Hunting Rifle',
  description: 'Are you in the market for flintlock hunting rifles? Visit Rock Island Auction today '
    + 'to browse our extensive catalog of flintlock hunting rifles. Browse completed listings to plan for '
    + ' your next investment, create an account today & get ready for our next auction! ',
  queries: (info) => [{
    title: 'Flintlocks for Sale',
    query: [
      'defType=edismax',
      'fq=flintlock',
      'fq=!hlo:H',
      'fq=!hlo:O',
      `fq=auctionId:(${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')})`,
      'rows=3',
    ],
  }],
}, {
  slug: 'derringer/antique',
  title: 'Antique Derringer',
  description: 'Are you looking for a collectible antique derringer pistol for sale? Visit Rock Island '
    + 'Auction today to browse our extensive catalog of antique Derringer auctions. Browse completed listings '
    + 'to plan for your next investment, create an account today & get ready for our next auction!',
  queries: (info) => [{
    title: 'Shop our Antique Derringers',
    query: [
      'defType=edismax',
      'fq=derringers',
      'fq=!hlo:L',
      'fq=!hlo:O',
      `fq=auctionId:(${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')})`,
      'rows=3',
    ],
  }],
}, {
  slug: 'rifles/antique',
  title: 'Antique Rifles',
  description: 'Antique rifles offer a unique story as well as a display of beautiful craftsmanship.  Rock Island '
    + 'Auction Company is the industry leader in firearms auction houses and is the best place to find the perfect '
    + 'rifle to add to your collection.',
  queries: (info) => [{
    title: 'Buy Antique Rifles',
    query: [
      'defType=edismax',
      'fq=rifle',
      'fq=antique',
      'fq=!hlo:H',
      'fq=!hlo:O',
      `fq=auctionId:(${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')})`,
      'rows=3',
    ],
  }],
}, {
  slug: 'rapier/antique',
  title: 'Antique Rapier',
  description: 'Purchase an antique Rapier and add a piece of 16th century history to your weapons collection. '
  + 'These fine swords have incredible craftsmanship, and will leave your guests in awe when they see one on display.',
  queries: (info) => [{
    title: 'Buy Antique Rapier',
    query: [
      'defType=edismax',
      'fq=rapier',
      'fq=hlo:O',
      `fq=auctionId:((${(info.auctions.map((auction) => auction.AuctionID)).join(' OR ')}) OR
                    (${(info.auctions.map((auction) => (auction.AuctionID - 1))).join(' OR ')}) OR
                    (${(info.auctions.map((auction) => (auction.AuctionID - 2))).join(' OR ')}))`,
      'sort=auctionId desc',
      'rows=3',
    ],
  }],
}, {
  slug: 'nra-firearms-for-freedom',
  title: 'NRA Firearms for Freedom',
  description: 'Purchase an NRA firearm for freedom to support the NRA and its four charitable non-profit affiliates.',
  queries: (info) => info.auctions.map((auction) => ({
    title: auction.Title,
    viewAllLink: `/catalog/${auction.AuctionID}`,
    query: [
      'defType=edismax',
      'fq=NOT NRAID:false',
      `fq=auctionId:(${auction.AuctionID})`,
      'rows=6',
    ],
  })),
}, {
  slug: 'greg-lampe-collection',
  title: 'Rock Island Auction Company Proudly Presents The Greg Lampe Collection',
  description: 'Rock Island Auction Company Proudly Presents The Greg Lampe Collection',
  queries: () => [],
}, {
  slug: 'norman-blank-collection',
  title: 'Rock Island Auction Company Proudly Presents The Norman R. Blank Collection',
  description: 'Rock Island Auction Company Proudly Presents The Norman R. Blank Collection',
  queries: () => [],
}, {
  slug: 'riac-on-time',
  title: 'Rock Island Auction Company On Time!',
  description: 'Our newest auction format is RIAC ON TIME!',
  queries: () => [],
}, {
  slug: 'will-primos-collection',
  title: 'Rock Island Auction Company Proudly Presents The Will Primos Collection',
  description: 'Rock Island Auction Company Proudly Presents The Will Primos Collection',
  queries: () => [],
}];

export default allData;
