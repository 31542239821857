import React, { useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';

import { SocketHookManager } from 'src/lib/SocketHookManager';

interface ISocketContext {
  socket: Socket;
  socketHookManager: SocketHookManager;
  sendEvent: (event: string, ...args: any[]) => void;
  showIcon: boolean;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  setOnOnTimePage: (onTimePage: boolean) => void;
}

export const SocketContext = React.createContext<Partial<ISocketContext>>({});

export const SocketContextConsumer = SocketContext.Consumer;
export const SocketContextProvider = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  const [socket, setSocket] = React.useState<null | Socket>(null);
  const [socketHookManager, setSocketHookManager] = useState<SocketHookManager>();
  const [connected, setConnected] = React.useState<boolean>(true);
  const [onOnTimePage, setOnOnTimePage] = React.useState(false);
  const [showIcon, setShowIcon] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    console.log(`attempting connecting to socket at ${window.SOCKET_HOST}`);
    const socket = io(`${window.SOCKET_HOST}`, {
      path: '/api/socket.io',
      transports: ['websocket', 'polling'],
      secure: true,
      withCredentials: true,
    });
    socket.on('connect_error', (err) => {
      setConnected(false);
      // console.log(err.message);
      // console.log(err.description);
      // console.log(err.context);
    });
    socket.on('connect', () => {
      setConnected(true);
    });
    socket.on('disconnected', () => {
      setConnected(false);
    });
    socket.onAny((event, ...args) => {
      // console.log(event, ...args);
    });
    setSocket(socket);
    setSocketHookManager(new SocketHookManager(socket));
  }, []);

  useEffect(() => {
    const shouldShowModal = () => {
      if(connected || !onOnTimePage) {
        return;
      }
      // Only show the message every 10 minutes
      const timestamp = localStorage.getItem('disconnectionTime');
      if(!timestamp || (parseInt(timestamp) + 600000) < Date.now()) {
        setShowModal(true);
      }
    };
    setShowIcon(!connected);
    shouldShowModal();
  }, [connected, onOnTimePage]);

  useEffect(() => {
    if(showModal) {
      localStorage.setItem('disconnectionTime', String(Date.now()));
    }
  }, [showModal]);

  const sendEvent = (event: string, ...args: any[]): void => {
    if(socketHookManager.socket) {
      socketHookManager.socket.emit(event, ...args);
    }
  };

  return (
    <SocketContext.Provider value={{
      socket,
      socketHookManager,
      sendEvent,
      showIcon,
      showModal,
      setShowModal,
      setOnOnTimePage,
    }}>
      {children}
    </SocketContext.Provider>
  );
};
